import type { FormPostSubmitFields } from "../../translation-keys/post-submit-text-keys.js";

const BlindHeading ="当社のコンテンツに興味をお持ちいただき、ありがとうございます。下のボタンをクリックして、ダウンロードを開始してください。";
const BlindSubmitBody ="";
const Heading ="当社のコンテンツに興味をお持ちいただき、ありがとうございます。";
const Body ="マスターコントロールの専有ドキュメントと動画にアクセス出来るようになりました";
const Download = "今すぐダウンロード";
const PostFormFieldMap: FormPostSubmitFields = {
  "document-BlindSubmitHeading": "当社のコンテンツに興味をお持ちいただき、ありがとうございます。左側のドキュメントをクリックしてダウンロードしてください。",
  "document-BlindSubmitBody": "",
  "document-Heading": "",
  "document-Body": "当社のコンテンツに興味をお持ちいただき、ありがとうございます。下のボタンをクリックして、ダウンロードを開始してください。",
  "document-Download": Download,
  "document-landing-page-BlindSubmitHeading": "当社のコンテンツに興味をお持ちいただき、ありがとうございます。下のボタンをクリックして、ダウンロードを開始してください。",
  "document-landing-page-BlindSubmitBody": "マスターコントロールの専有ドキュメントと動画にアクセス出来るようになりました",
  "document-landing-page-Heading": Heading,
  "document-landing-page-Body": "フォームにご入力いただきありがとうございます。ドキュメントを添付したEメールをお送りしました。以下をクリックすると今すぐ直接ダウンロードいただけます。",
  "document-landing-page-Download": Download,
  "post-event-assets-BlindSubmitHeading": BlindHeading,
  "post-event-assets-BlindSubmitBody": BlindSubmitBody,
  "post-event-assets-Heading": Heading,
  "post-event-assets-Body": Body,
  "post-event-assets-Download": Download,
  "ppc-BlindSubmitHeading": BlindHeading,
  "ppc-BlindSubmitBody": BlindSubmitBody,
  "ppc-Heading": "当社のコンテンツに興味をお持ちいただき、ありがとうございます。下のボタンをクリックして、ダウンロードを開始してください。",
  "ppc-Body": Body,
  "ppc-Download": Download,
  "case-study-BlindSubmitHeading": BlindHeading,
  "case-study-BlindSubmitBody": BlindSubmitBody,
  "case-study-Heading": Heading,
  "case-study-Body": Body,
  "case-study-Download": Download,
  "video-BlindSubmitHeading": "当社のコンテンツに興味をお持ちいただき、ありがとうございます。お客様が興味のありそうな追加のリソースをご紹介します。",
  "video-BlindSubmitBody": "",
  "video-Heading": Heading,
  "video-Body": Body,
  "video-Download": Download,
  "video-landing-page-BlindSubmitHeading": "当社のコンテンツに興味をお持ちいただき、ありがとうございます。下のボタンをクリックして、動画をご覧ください。",
  "video-landing-page-BlindSubmitBody": "",
  "video-landing-page-Heading": Heading,
  "video-landing-page-Body": "",
  "video-landing-page-Download": "動画を再生",
  "recommended-resources-BlindSubmitHeading": "",
  "recommended-resources-BlindSubmitBody": "",
  "recommended-resources-Heading": "当社のコンテンツに興味をお持ちいただき、ありがとうございます。下のボタンをクリックして、ダウンロードを開始してください。",
  "recommended-resources-Body": "",
  "recommended-resources-Download": "",
  "general-modal-BlindSubmitHeading": "",
  "general-modal-BlindSubmitBody": "",
  "general-modal-Heading": "ご希望のリソース",
  "general-modal-Body": "ありがとうございました。ドキュメントのリンクが記載されたEメールを受け取るか、今すぐダウンロードいただけます。",
  "general-modal-Download": ""
};
export default PostFormFieldMap;